.plan-title {
  font-size: 0.95rem;
}

.list-group-item small {
  font-size: 0.95rem;
}

.card button {
  font-size: 1.08rem;
}

.plan-button {
  font-size: 1.45rem !important;
}

@media(min-width: 992px) and (max-width: 1200px) {
  .plan-button {
    font-size: 1.375em !important;
  }
}