.empty-container{
  min-height:calc(100vh - 74px);
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty-import-divider{
  margin:12px 0;
}

.plaid-link{
  padding: 0.5rem 0.75rem;
  border-radius: 0.375rem;
  background-color: #236cdc;
  border-color: #236cdc;
  color: "white";
}

.institution{
  margin-right: 8px;
}

@media all and (min-width: 768px) {
  .empty-import-bar{
    display: flex;
    align-items: center;
  }
  .empty-import-divider{
    margin-top:0;
    margin-bottom:0;
  }
}
