/* fix some weird issues with the select2 theme */
.select2-container .select2-search--inline {
    display: block !important;
}
.select2-container .select2-search--inline .select2-search__field {
    margin-top: 0 !important;
    width: unset !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
    margin-top: 2.5px !important;
}
.select2-container--default .select2-selection--multiple {
    height: unset !important;
}