.tab {
  cursor: pointer;  
}

.disabled-tab {
  color: darkgray !important;
}

.disabled-tab:hover {
  color: darkgray !important;
  cursor: default;
}