.ticker-symbol{
  width:48px;
  height:48px;
  min-width: 48px;
  max-width: 48px;
  min-height: 48px;
  max-height: 48px;
  background-color:#12263F;
  color:white;
  font-size:13px;
  border:1px solid #343a40;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-right:12px;
  padding:0;
  border-radius:0.25rem;
  box-sizing: border-box;
}
.ticker-symbol span{
  color:white;
  background: linear-gradient(45deg, #10C4D9, #96C64D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ticker-name {
  font-size:11px;
  text-transform: uppercase;
  opacity:0.8;
}

.ticker-price{
  font-size:18px;
  font-weight:700;
  line-height:24px;
}

.ticker-price__percentage{
  font-size:12px;
}
.ticker-price.is--up .ticker-price__percentage, .ticker-price.is--up .ticker-price__icon{
  color:#009F70;
}

.ticker-price__icon{
  font-size:14px;
  vertical-align: middle;
  transform: translateY(1px);
  display: inline-block;
}
