.table-container {
  width: 100%;
  background: white;
  box-sizing: border-box;
}

.table-sticky {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 140px;
  box-shadow:0 0.75rem 1.5rem rgba(18,38,63,.03)
}

.table-header-wrapper{
  height:72px;
  overflow: hidden;
}

.table-header {
  padding-bottom: 32px;
  overflow-x: scroll;
  overflow-y: hidden;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
}

.table-header table th{
  background-color:#3B506C;
  border-color:rgba(0,0,0,0.15);
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  height:72px;
  box-sizing: border-box;
  text-align: right;
  align-items: center;
  vertical-align: middle;
}

.table-header table th .row-content{
  position: relative;
}
.table-header table .sticky-left .row-content{
  justify-content: flex-start;
  width: auto;
}
.table-header table th .row-content .sort-icon{
  position: absolute;
  right:-20px;
  top:50%;
  transform:translateY(-50%);
}
.table-header table th:nth-child(2) .row-content{
  width:64px;
  justify-content: flex-start;
}
.table-header table th:last-child .row-content,
#portfolio-table-body table td:last-child .row-content
#portfolio-table-footer table td:last-child .row-content{
  padding-right:12px;
}
.table-header table th:last-child .row-content img,
#portfolio-table-body table td:last-child .row-content img
#portfolio-table-footer table td:last-child .row-content img{
  right:-12px;
}

.obscured .row-content{
  filter:blur(3.5px);
}


#portfolio-table-body{
  padding-bottom:32px;
  margin-bottom:-32px;
  min-height: calc(100vh - 734px);
  background-color:#f8f9fa;
}
#portfolio-table-body table tbody tr:first-child td, #portfolio-table-body table tbody tr:first-child th{
  border-top:none;
}
#portfolio-table-body table tbody tr td{
  text-align: right;
  font-variant-numeric: tabular-nums;
  box-shadow: inset 0 -1px 0 #edf2f9;
  background-color:white;
}
#portfolio-table-body table tbody tr td:nth-child(2) .row-content{
  width:64px;
  justify-content: flex-start;
}

.table-header-column:hover {
  cursor: pointer;
}

.table-header .sticky-left{
  left: 0;
  border-right:1px solid #edf2f9;
  border-bottom: none;
  border-top: none;
  box-shadow: none;
}

.table--scroll {
  overflow-x: scroll !important;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  /* background: white; */
  z-index: 1;
}

.sticky-left {
  left: 0;
  background-color:white;
  border-top:1px solid #edf2f9;
  border-right:1px solid #edf2f9;
  border-bottom:1px solid #edf2f9;
  box-shadow:0.75rem 0 1.5rem rgba(18,38,63,.03);
}


.sticky-bottom {
  bottom: 0;
}

.table-footer {
  z-index: 2
}
.table-footer .table-secondary{
  background-color:#3B506C;
  font-weight:700;
  color:white;
}
.table-footer .table-secondary .sticky-left{
  border-right: 1px solid rgba(0,0,0,0.05);
  border-bottom:none;
}

.table-footer table tbody tr td{
  font-variant-numeric: tabular-nums;
}
.table-footer table tbody tr th .row-content{
  justify-content: flex-start;
}
.table-footer table tbody tr td:first-of-type .row-content{
  width:64px;
  justify-content: flex-start;
}

.company {
  z-index: 2;
}

.company:hover {
  box-shadow: 0 0 12px rgba(0,0,0,0.25);
  transform: translateY(-4px);
}

.company:hover > div > div > div > h6 { 
  text-decoration: underline;
}

.transaction-type {
  margin-left: 24px;
}

.row-content {
  width: 120px;
  box-sizing: border-box;
  margin-left: 5px;
}

th:first-child .row-content {
  margin-left: 24px;
}

.row-historical {
  background: #EDF2F9;
}
.row-historical .sticky-left{
  border-color: rgba(0,0,0,0.05);
  box-shadow:none;
  border-top:none !important;
}

.no-border-space {
  border-bottom: 1px solid #6E84A3;
}

/* CSS Transition Group */
.historicalDataDropdown-appear {
  opacity: 0.01;
  transform:translateY(-40px);
}
.historicalDataDropdown-appear.historicalDataDropdown-appear-active {
  opacity: 1;
  transform:translateY(0);
  transition: all .5s cubic-bezier(0.23, 1, 0.32, 1);
}

.table-upgrade-msg-wrapper {
  height: 0;
  position: sticky;
  top: 150px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-upgrade-msg {
  display: flex;
  flex-direction: column;
}
