/* // TODO : disregard in PR */
.box-bottom {
    flex-flow: column;
}

.truncate-tooltip {
    visibility: hidden;
    height: 0px;
  }
  
  @media only screen and (max-width: 992px) {
    .truncate {
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .truncate-tooltip {
      visibility: visible;
      height: 0px;
    }
    .hide-for-tooltip {
      visibility: hidden; 
      height: 0px;
    }
  }