.watch-tutorial {
  cursor: pointer;
}

.tutorial-thumbnail {
  width: 200px;
  overflow: hidden;
  border-radius:0.5em;
  display: block;
}
.tutorial-thumbnail img{
  width:100%;
  transition:all 0.1s ease-in-out;
}
.tutorial-thumbnail:hover{
  cursor: pointer;
}
.tutorial-thumbnail:hover img{
  transform:scale(1.05);
}

.import-button {
  height: 56px;
}

@media all and (max-height: 776px){
  .tutorial-thumbnail{
    height: 0;
  }
}

.column-picker-container {
  overflow-x: auto;
}
