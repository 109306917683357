.toast-container {
  position: absolute;
}

.toast {
  z-index: 99999;
}

.toast-enter,
.toast-appear {
  opacity: 0.01;
}

.toast-enter.toast-enter-active,
.toast-appear.toast-appear-active {
  opacity: 1.0;
  transition: opacity 250ms ease-in;
}

.toast-leave {
  opacity: 1.0;
}

.toast-leave.toast-leave-active {
  opacity: 0.0;
  transition: opacity 250ms ease-in;
}