.input-display:hover{
  cursor: text;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding-right: 12px;
  height: 40px;
}

.table.table-sm tr:first-child td{
  border-top:none;
}
.table.table-sm tr td{
  border-left:1px solid #EDF2F9;
}
.table.table-sm tr td:first-child{
  border-left:none;
}

.card .table thead tr th{
  border-top:none;
}
.card .table{
  margin-bottom:0;
}
