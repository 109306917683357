.icon-margins {
  margin-left: 1rem;
}

@media all and (max-width: 1000px){
  .inner-container { 
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .add-card {
    height: 140px;
    flex-direction: column;
    justify-content: space-between;
  }
  .icon-margins {
    margin: 0;
  }
}

.subtitle {
  color: grey;
  font-size: 0.7rem;
}