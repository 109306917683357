.upload-box-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* TODO: Update below temporary CSS */
  color: darkgray;
  min-height: auto;
  height: 100%;
}

.upload-box {
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* TODO: Update below temporary CSS */
  border-radius: 4px;
  border: 1px dashed lightgray;
}

.upload-box::after {
  content:'';
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    left:0;
    background-color:rgba(blue, 0.08);
    pointer-events: none;
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    transition: all 0.15s ease-in-out;
}

.dragging {
  position: relative;
}

.dragging .upload-box {
  border-style:solid;
  border-width: 2px;
  border-color: darkslategray;
}

.dragging .upload-box::after {
  visibility: visible;
  opacity:1;
}

.dragging h1, 
.dragging p,
.dragging p > span, 
.dragging div {
  opacity: 0.8;
}

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.childrenWrapper {
  width:100%;
  position: relative;
  overflow: hidden;
}

.childrenWrapper .loading {
  visibility: hidden;
}