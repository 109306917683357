.icon-large{
  font-size: 20px;
}
.fill-blue{
  fill: #2C7BE5;
}
.dropdown-container{
  position: relative;
}
.extra-large-font{
  font-size: 40px;
}
.full-height{
  height: 90vh;
  overflow: hidden;
}
.underline{
  text-decoration: underline;
}
.font-11{
  font-size: 11px;
}
.font-12{
  font-size: 12px;
}
.font-13{
  font-size: 13px
}

.font-m{
  font-size: 20px;
}

.font-l{
  font-size: 25px;
}

.full-width{
  width: 100vw;
}
.input-radius{
  border-radius: 8px 0px 0px 8px !important;
}
.border-white{
  border: 1px solid white;
}
.badge-size{
  display: inline-block;
  width: 33px;
  height: 33px;
}
.invisible{
  visibility: hidden;
}
.no-highlight{
  outline: none;
}
.cursor-pointer{
  cursor: pointer;
}
.right-five{
  right: 5%;
}
.dropdown-wide{
  width: 300px;
}
.rotate-arrow{
  transform: rotate(180deg);
}
.column-wide{
  min-width: 400px;
  max-width: 400px;
  width: 400px;
}

.circle-button{
  height: 20px !important;
  width: 20px !important;
}

.block-pointer{
  cursor: text;
  pointer-events: none;
}
.heavy-margin{
  margin-top: 40px;
  margin-left: 15%;
  margin-right: 15%;
}
.dropdown-overflow{
  height: 50vh;
  overflow-y: scroll;
}
.tooltip-inner{
  background-color: #6E84A3;
  color: white;
}
.tooltip .arrow:before {
  border-bottom-color:#6E84A3 !important;
  border-top-color:#6E84A3 !important;
}

.hideContent {
  opacity: 0;
}
.customTooltip {
  position: relative;
  display: inline-block;
}

.bg-trans {
  background: transparent !important;
}

input::-ms-clear, input::-ms-reveal {
  display: none;
}

.absolute-center { 
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
