.navbar {
  background-color: white !important;
  border:none !important;
  box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);
}

#tdh-lock{
  display: flex;
  flex-direction: row;
}

.right-icons {
  margin-left: auto;
}

.progress-container {
  width: 50%;
}

.navbar-collapse{
  margin-left:0;
  margin-right:0;
}

.navbar-nav .nav-link > .fe{
  line-height: 1em !important;
}

.progress{
  width: 100%;
}
@media only screen and (max-width: 996px) {
  .logo-centered {
    margin: auto;
  }
  .navbar-collapse{
    margin-left:-1em;
    margin-right:-1em;
  }
}
@media only screen and (min-width: 992px){
  .navbar-expand-lg .navbar-nav .nav-link{
    padding: 0.625rem 0.5rem 0.5rem 0.5rem;
  }
  .navbar-expand-lg .navbar-nav .nav-link.active:before {
    border-bottom-width:2px !important;
    bottom: calc(-0.75rem - 0px) !important;
  }
}
.dropdown-wide{
  width: 400px !important;
}
