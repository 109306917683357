.notification-container{
  max-height:360px;
  overflow-y: auto;
}
.notification-list{
  list-style: none;
  padding:0;
  padding-top:6px;
  margin-left:24px;
}
.notification{
  padding-top:12px;
  padding-right:24px;
  padding-bottom:12px;
  border-bottom:1px solid rgba(0,0,0,0.05);
  display: flex;
  justify-content: space-between;
  color:rgba(0,0,0,0.7);
  position: relative;
}
.notification.new::after{
  content:'';
  display: block;
  width:3px;
  height:calc(100% - 32px);
  background-color:#2C7BE5;
  position: absolute;
  left:-14px;
  top:16px;
  /* transform:translateY(-50%); */
}
.notification strong{
  color:black;
}
.notification:last-child{
  border-bottom:none;
}
.notification-text{
  flex-grow:1;
}
.notification .timestamp{
  width:64px;
  font-size:0.875em;
  opacity: 0.8;
  transform:translateY(2px);
  text-align: right;
}
.notification-pretitle{
  font-size:0.75rem;
  display: block;
  color:#6E84A3;
  margin-top:4px;
}
.notification-pretitle a{
  /* text-decoration:underline !important; */
}
