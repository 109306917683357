.card-full-height {
    min-height: 100%;
}

.card-hoverable {
    color: #2C7BE5;
}

.card-hoverable:hover {
    cursor: pointer;
    color: #2A57A9;
}