.btn.btn-secondary.active {
  z-index: 0 !important;
}

/*  ODT-378 */
/* .btn.btn-primary{
  background-image: linear-gradient(-132deg, #10C4D9 0%, #96C64D 100%);
  background-size:100% 100%;
  border:none;
  transition:all 0.2s ease-in-out;
}
.btn.btn-primary:hover{
  background-size:300% 100%;
} */

.btn.btn-primary .loader {
  border-color: white;
  border-bottom-color: transparent;
}

.buttonTitleWithLoader{
  display: flex;
  align-items: center;
}