.table-control-container {
  padding:0 16px;
  display: flex;
  justify-content: space-between;
  background: white;
}

.control-section {
  margin:8px 0;
  display: flex;
  align-items: center;
}

/* .control-section > button {
  margin: 8px !important;
}

.control-section > .input-group {
  margin: 0 8px !important;
} */
.truncate-tooltip {
  visibility: hidden;
  height: 0px;
}

@media only screen and (max-width: 992px) {
  .truncate {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .truncate-tooltip {
    visibility: visible;
    height: 0px;
  }
  .hide-for-tooltip {
    visibility: hidden;
    height: 0px;
  }
}

.nav-tabs.nav-tabs-table-control {
  border-bottom: none;
  margin-bottom: calc(-0.375rem + -8px);
}

.nav-tabs.nav-tabs-table-control .nav-item a {
  border-bottom-width: 2px;
}

.fade-in-right {
  transition: all 0.2s ease 0.3s;
  visibility: hidden;
  opacity: 0;
  transform: translateX(100%);
}

.fade-in-right.fade-in {
  transform: unset;
  opacity: 1;
  visibility: visible;
}