.footer{
  margin:48px 0;
  padding-top:48px;
  border-top:1px solid rgba(0,0,0,0.1);
}

.footer-left, .footer-right{
  display: flex;
  flex-direction: row;
}

.footer-links{
  padding-left:24px;
}
.footer-links li{
  display: inline-block;
  list-style: none;
  margin-left:16px;
}
.footer-links li:first-child{
  margin-left:0;
}
