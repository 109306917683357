.column-picker-inner-container {
  width: 300px;
}

.list-group-item {
  min-height: 48px;
}

.column-list {
  height: 200px
}

.card-table{
  list-style:none;
  padding:0;
  margin:0;
}
.card-table-item{
  width:100%;
  line-height:48px;
  padding:0 16px;
  border-bottom:1px solid rgba(0,0,0,0.03);
}
.card-table-item:nth-child(odd){
  background-color:#f8f9fa;
}
.card-table-item:last-child{
  border:none;
}

.overlay {
  position: absolute;
  background: rgba(0,0,0,0.4);
  width: 100%;
  height: calc(100% - 56px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius:0.5em 0.5em 0 0;
}
