.history-annual-income {
    display: table;
    background-color: #24374c;
    font-size: 0.82em;
    color: var(--white);
    border-radius: 8px;
}

.history-annual-income .income-row {
    display: table-row;
    flex-direction: row;
}

.history-annual-income .income-col {
    display: table-cell;
    width: 50%;
    padding: 12px;
    border-right: 1px solid #2e4768;
    border-bottom: 1px solid #2e4768;
    white-space: nowrap;
}

.history-annual-income .income-col:last-child {
    border-right: none;
}

.history-annual-income .income-row:last-child .income-col {
    border-bottom: none;
}