#progress-svg {
  transform: rotate(-90deg);
}

#progress-svg circle {
  transition: stroke-dashoffset 1s linear;
}

#progress-div {
  position: relative;
}
#progress-div:after {
  position: absolute;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  content: attr(data-pct)"%";
  border-radius: 100%;
  font-size: 0.7em;
}