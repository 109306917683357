.card.card-estimate:before {
    content: 'Estimate';
    position: absolute;
    top: 0;
    right: 0;
    margin: 6px 12px;
    font-style: italic;
    font-size: 0.82em;
    color: var(--gray);
}

.close-button {
    background-color: #6e84a2;
    width: 20px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px 0.5625rem 0;
    cursor: pointer;
}

.close-button .close-icon {
    font-size: 20px;
}

.forecast-breakdown-wrapper {
    max-height: 257px;
    overflow-y: scroll;
}

.forecast-breakdown {
    display: table;
    background-color: #24374c;
    font-size: 0.82em;
    color: var(--white);
    border-radius: 8px;
    width: 100%;
}

.forecast-breakdown .forecast-disclaimer {
    font-size: 0.62em;
}

.forecast-breakdown .ticker-row {
    display: table-row;
    flex-direction: row;
}

.forecast-breakdown .ticker-col {
    display: table-cell;
    width: 50%;
    padding: 12px;
    border-right: 1px solid #2e4768;
    border-bottom: 1px solid #2e4768;
    white-space: nowrap;
}

.forecast-breakdown .ticker-col:last-child {
    border-right: none;
}

.forecast-breakdown .ticker-row:last-child .ticker-col {
    border-bottom: none;
}

/* CSS Transition Group */
.barchart-breakdown-appear {
    opacity: 0.01;
    transform:translateX(300px);
}

.barchart-breakdown-appear.barchart-breakdown-appear-active {
    opacity: 1;
    transform:translateX(0);
    transition: all .5s cubic-bezier(0.23, 1, 0.32, 1);
}

.barchart-breakdown-leave {
    opacity: 1;
    transform:translateX(0);
  }

.barchart-breakdown-leave.barchart-breakdown-leave-active {
    opacity: 0.0;
    transform:translateX(300px);
    transition: all .5s ease-out;
}
