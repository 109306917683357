.input-field-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.no-border-radius {
  border-radius: 0 !important;
}

.invalid-color{
  color: #E63757;
}

.hide-invalid-feedback{
  visibility: hidden;
}

.no-border-radius-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}