.box-right{
  display: flex;
  padding-right:24px !important;
}
.box-right .card{
  width: 100%;
  margin:24px 0;
  overflow: hidden;
  border: none !important;
}
.box-right .card .card-header{
  flex-grow: 0;
  background-color:white;
}
.scenario-items-container {
  flex-grow: 1;
  position: relative;
}

.scenario-item__table-container{
  box-shadow: inset 0 0.75rem 1.5rem rgba(18,38,63,0.15);
  margin-bottom: 24px;
  overflow: hidden;
}

.scenario-item{
  display: flex;
  flex-direction: column;
}
.scenario-item__row.tooltip-trigger{
  background-color:white;
}
.scenario-item__row.tooltip-trigger .fe{
  display:inline-block;
  transform:translateY(1px);
}

.scenario-item .table-sm tbody tr td:nth-child(2){
  width:108px;
  max-width: none;
}
.scenario-item:last-child .scenario-item__cost-bar{
  border-radius:0 0 0.5rem 0.5rem;
}

.scenario-item__row{
  padding:12px 16px;
  border-bottom:1px solid #EDF2F9;
}

.scenario-item__cost-bar{
  -webkit-sticky: sticky;
  position: sticky;
  bottom:0;
  color:white;
  background-color:#3B506C;
}

.scenario-item:last-child .scenario-item__empty{
  padding-bottom:13px;
}
.disabled-background {
  background: #EDF2F9;
}

.upgrade-message {
  position: absolute;
  background: rgba(18,38,63,0.7);
  backdrop-filter:blur(2px);
  z-index: 100;
  top: 72px;
  bottom:0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upgrade-message.active-scenario{
  top:309px;
}
.upgrade-message .btn{
  margin-top:-40px;
}

.scenarios-maximize-button{
  position: absolute;
  top: 64px;
  right: 24px;
}
.scenarios-maximize-button .card{
  border-top-left-radius:0px;
  border-top-right-radius:0px;
  box-shadow:inset 0 4px 8px -4px rgba(0,0,0,0.2);
}

.table-padding {
  padding: 0.9375rem;
  padding-bottom: 0;
}

/* CSS Transition Group */
.what-if-table-appear {
  opacity: 0.01;
  transform:translateY(-300px);
}
.what-if-table-appear.what-if-table-appear-active {
  opacity: 1;
  transform:translateY(0);
  transition: all .5s cubic-bezier(0.23, 1, 0.32, 1);
}
